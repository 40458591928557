.quote {
	--quote-offset: 48px;
	position: relative;
	.signed {
		margin-top: 32px;
	}
	.larry {
		position: absolute;
		bottom: 0;
		right: var(--quote-offset);
		transform: translateY(50%);
	}
	:global(.screen-small) & {
		--quote-offset: 24px;
	}
}

.shopCarouselWrapper {
	padding: 0 !important;
}
