.container {
	display: flex;
	flex-direction: column;
	padding: var(--grid-gutter) calc(var(--grid-gutter) / 2) 0;
	max-width: 1920px;
	margin: 0 auto;
	min-height: 100vh;
	background: #000;
}

.grid {
	display: flex;
	flex: 1 1 auto;
	&.stacked {
		flex-direction: column;
	}
	&.vhFill {
		min-height: calc(100vh - var(--headerHeight) - (var(--grid-gutter) * 2));
	}
	&.reverse {
		flex-direction: row-reverse;
	}
	&.stacked.reverse {
		flex-direction: column-reverse;
	}
	.grid {
		margin: 0 calc(var(--grid-gutter) / -2) calc(var(--grid-gutter) * -1);
	}
	.grid + .grid {
		margin-top: var(--grid-gutter);
	}
}

.cell {
	display: flex;
	flex-direction: column;
	padding: 0 calc(var(--grid-gutter) / 2) var(--grid-gutter);
	flex: 1;
	.grid {
		margin-bottom: calc(var(--grid-gutter) * -1);
	}
	.grid + .grid {
		margin-top: var(--grid-gutter);
	}
}

.content {
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: #252527;
	height: 100%;
	padding: 8px 8%;
	&.light {
		background: #303032;
	}
	&.bg {
		background-size: cover;
		background-position: center center;
		padding: 0;
		:global(.screen-small) &,
		:global(.screen-medium) & {
			height: 300px;
		}
	}
	&.pad1,
	&.pad2 {
		padding: 40px 8% 48px;
	}
	:global(.screen-large) &.pad2 {
		padding-top: 80px;
		padding-bottom: 88px;
	}
}
a.content {
	transition: background 0.3s;
	&:hover {
		background-color: #000;
	}
}
