.mailingList {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.graphic {
	display: block;
	width: 100%;
	max-width: 326px;
	margin-bottom: 32px;
}

.form {
	display: flex;
	width: 100%;
	max-width: 448px;
	margin-top: 28px;
	input {
		height: 60px;
		background: none;
		border: none;
		padding: 16px;
		outline: none;
		font-size: 16px;
		line-height: 1;
		appearance: none;
		border-radius: 0;
		&::placeholder {
			line-height: normal;
		}
	}
	.input {
		flex: 1;
		background: #fff;
		color: #000;
	}
	.submit {
		margin-left: 8px;
	}
	:global(.screen-small) & {
		display: block;
		input {
			display: block;
			width: 100%;
		}
		.submit {
			margin: 8px 0 0;
		}
	}
}

.honeypot {
	position: absolute;
	left: -5000px;
}
