$gutter: 56px;

.appHeader {
	--height: auto;
	--cellPadding: 12px 20px;
	--cellGutter: 56px;
	display: flex;
	align-items: center;
	width: 100%;
	height: var(--height);
	background: #252527;
	padding-left: 20px;
	padding-right: 20px;
	.cell {
		display: flex;
		flex: 1;
		padding: var(--cellPadding);
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			justify-content: flex-end;
			padding-right: 0;
		}
	}
	:global(.screen-large) & {
		--height: var(--headerHeight);
		--cellPadding: calc(var(--cellGutter) / 2);
	}
}

.logo {
	display: block;
	width: 154px;
	margin: 0 auto;
	:global(.screen-small) & {
		width: 112px;
	}
}

.nav {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
}

.navItem {
	margin: 0 calc(var(--cellGutter) / 2);
}
