.image {
	display: block;
	margin: 0 auto 16px;
}

.cta {
	margin-top: 64px;
	text-align: center;
	@media print {
		display: none;
	}
}
