$screen-min: 600px;
$screen-max: 1180px;
$screen-diff: ($screen-max / 1px - $screen-min / 1px);

@mixin responsiveFontSize($font-min, $font-max) {
	font-size: $font-min;
	@media (min-width: $screen-min) and (max-width: $screen-max) {
		font-size: calc(
			#{$font-min} + (100vw - #{$screen-min}) / #{$screen-diff} * #{($font-max / 1px - $font-min / 1px)}
		);
	}
	@media (min-width: #{$screen-max + 1}) {
		font-size: $font-max;
	}
}

@mixin gin() {
	font-family: 'gin';
}

@mixin headline() {
	@include gin;
	line-height: 1.2;
}

.fs {
	p {
		line-height: 1.5;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		display: inline-block;
		position: relative;
		&:before {
			position: absolute;
			top: calc(100% - 2px);
			left: 0;
			width: 100%;
			content: '';
			height: 1px;
			background: currentColor;
			transition: all 0.15s ease-in-out;
			pointer-events: none;
		}
		&:hover {
			&:before {
				width: 0;
			}
		}
	}
	&.contained {
		max-width: 688px;
		margin: 0 auto;
	}
}

.h1 {
	@include headline;
	@include responsiveFontSize(24px, 40px);
}

.h2 {
	@include headline;
	@include responsiveFontSize(24px, 32px);
}

.h3 {
	@include headline;
	font-size: 20px;
}

.meta {
	opacity: 0.5;
	font-size: 14px;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 5px;
}

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.justify {
	text-align: justify;
}

.noWrap {
	line-height: 1;
	white-space: nowrap;
}
