.svg {
	fill: #fefefe;
	@media print {
		fill: #000;
	}
}

.lightwell,
.survey {
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
}

.lightwell {
	animation-name: slideInFromTop;
}

.survey {
	animation-name: slideInFromBottom;
}

@keyframes slideInFromTop {
	0% {
		opacity: 0;
		transform: translateY(-100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideInFromBottom {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
