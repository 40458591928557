.gallery {
	min-height: 400px;
	height: 80vh;
	:global(.screen-large) & {
		min-height: 700px;
		max-height: 900px;
	}
}

.image {
	background-size: cover;
	background-position: center center;
}
