.masthead {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.background {
	display: block;
	max-width: 100%;
	max-height: 320px;
	pointer-events: none;
}

.foreground {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	h2 {
		font-family: Gin;
	}
	.callout {
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 1;
		white-space: nowrap;
		margin-top: 0.75em;
		img {
			display: block;
			max-height: 42px;
			width: auto;
			margin-right: 8px;
		}
	}
	a.callout:hover,
	.callout a:hover {
		text-decoration: underline;
	}
}

.headlight {
	font-family: Gin;
	font-size: 320px;
	line-height: 1;
	text-transform: uppercase;
	color: #252527;
	margin: 0;
	transform: translateY(-0.055em);
	letter-spacing: -3px;
}
