.bio {
	img {
		max-width: 100%;
	}
}

.role {
	margin-top: 0.5em;
}

:global(.screen-large) {
	.bio {
		margin: 0 -4% !important;
		align-items: center;
		& > * {
			margin: 0 4%;
		}
		img {
			max-width: none;
		}
	}
}
