.club {
	display: flex;
	width: 100%;
	&__image {
		img {
			max-width: 165px;
		}
	}
	&__content {
		margin-left: 40px;
	}
	:global(.screen-small) & {
		flex-direction: column;
		align-items: center;
		&__content {
			margin-left: 0;
			margin-top: 40px;
		}
	}
}
