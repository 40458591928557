.vs-products {
	--vs-products-spacer: var(--vs-spacer, 1rem);
	--vs-products-gap: var(--vs-gap, 0.7rem);
	--vs-products-card-min-width: 325px;
	--vs-products-card-max-width: 400px;
	--vs-products-list-card-max-width: 578px;
	--vs-products-img-wrapper-width: 324px;
	--vs-products-img-wrapper-height: 276px;
	--vs-products-img-width: 100%;
	--vs-products-img-height: 276px;
	--vs-products-list-md-img-width: 450px;
	--vs-products-font-family: inherit;
	--vs-products-font-weight: var(--vs-font-weight, normal);
	--vs-products-font-size: 1.3125rem;
	--vs-products-line-height: 1.2;
	--vs-products-color: #ffffff;
	--vs-products-background-color: #252527;
	--vs-products-secondary-color: #6c757d;
	--vs-products-border-color: none;
	--vs-products-border-width: 1px;
	--vs-products-border-style: solid;
	--vs-products-border-radius: none;
	--vs-products-act-border-radius: var(--vs-products-border-radius, 0rem);
	--vs-products-headline-font-size: 0px;
	--vs-products-headline-display: none;
	--vs-products-border-style: var(--vs-products-internal-border-style, none);
	--vs-available-parent-margin-bottom: 0px;
	--vs-atc-button-background-color: #000;
	--vs-atc-button-border-color: #000;
	--vs-atc-button-hover-background-color: #fff;
	--vs-atc-button-hover-border-color: #fff;
	--vs-atc-button-hover-color: #000;
	--vs-available-spacer: calc(var(--vs-spacer, 1rem) * 1);
	--vs-available-header-font-family: var(--vs-header-font-family, inherit);
	--vs-available-header-font-weight: var(--vs-header-font-weight, 600);
	--vs-available-header-font-size: 1rem;
	--vs-available-titles-font-size: 5rem;
	--vs-available-titles-font-weight: 600;
	--vs-available-state-gap: calc(var(--vs-spacer, 0.5rem) * 1);
	--vs-available-state-font-size: 14px;
	--vs-available-state-font-weight: 500;
	--vs-available-state-line-height: 1.5;
	--vs-available-state-letter-spacing: normal;
	--vs-available-state-color: #fff;
	--vs-available-state-background-color: #000;
	--vs-available-state-min-width: 36px;
	--vs-available-state-padding: 4px 8px;
	--vs-available-state-border-radius: none;
	--vs-available-state-tooltip-color: #000;
	--vs-available-state-tooltip-background-color: #fff;
	--vs-available-state-tooltip-padding: 12px 16px;
	--vs-available-state-tooltip-border-radius: 0px;
	--vs-available-state-tooltip-font-size: 1rem;
	--vs-available-state-tooltip-font-weight: normal;
	--vs-available-state-tooltip-offset: 0px;
	--vs-available-state-tooltip-arrow-size: 4px;
	--vs-available-states-state-background-color: none;
	--vs-available-pickups-font-size: var(--vs-available-state-font-size, 12px);
	--vs-available-pickups-font-weight: var(--vs-available-state-font-weight, 600);
	--vs-available-pickups-line-height: var(--vs-available-state-line-height, 1);
	--vs-available-pickups-letter-spacing: var(--vs-available-state-letter-spacing, normal);
	--vs-available-pickups-min-width: var(--vs-available-state-min-width, 36px);
	--vs-available-pickups-padding: var(--vs-available-state-padding, 4px 8px);
	--vs-available-pickups-border-radius: var(--vs-available-state-border-radius, 0rem);
	--vs-available-pickups-text-transform: uppercase;
	--vs-products-margin-bottom: 0;
	--vs-link-color-rgb: 255, 255, 255;

	:global {
		.vs-available-parent {
			background-color: #252527;
			padding: 16px;
			padding-top: 24px;
			padding-bottom: 24px;
			margin-bottom: var(--vs-products-gap);
		}
		.vs-products-headline {
			display: none;
		}
		.vs-available-extras {
			display: none;
		}
		.vs-available-parent .vs-available-header {
			margin-top: 0;
		}
		@media screen and (max-width: 523px) {
			.vs-products-parent .vs-products-item-stage {
				.vs-products-item-actions {
					margin: 0;
					padding: 24px;
				}
				.vs-atc-form {
					display: block;
				}
				.vs-atc-select,
				.vs-atc-button-stage {
					width: 100%;
				}
				.vs-atc-button-stage {
					margin-top: 16px;
				}
			}
		}
	}
}